import React, { useState } from "react";
//import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
// import Modal from "@material-ui/core/Modal";
// import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";
// import CloseIcon from "@material-ui/icons/Close";
// import voteAuth from "../../../../assets/images/vote-auth.png";
// import thanks from "../../../../assets/images/thanks.png";

// import Reaptcha from "reaptcha";
// import {
//   Async,
//   FieldFeedback,
//   FieldFeedbacks,
//   FormWithConstraints,
// } from "react-form-with-constraints-material-ui";
import LazyLoad from "react-lazyload";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Placeholder from "../../../../assets/images/Event-Card-Placeholder-1.png";
//import Winner from "../../../../assets/images/Eventeer-24-Badge-Winner.png";

var settings = {
  dots: true,
  arrows: true,
  infinite: false,
  draggable: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <ArrowForwardIcon />,
  prevArrow: <ArrowBackIcon />,
  useTransform: false,
};

const NomineeCard = ({ event_data, collection_id }) => {
  const style = eventeerStyles();
  const classes = style();

  const [voteResponse, setVoteResponse] = useState({
    status: "",
    msg: "",
    error: "",
  });

  // const [captcha, setCaptcha] = useState("");
  // function verifyCaptcha() {
  //   setCaptcha("true");
  // }

  // const [open, setOpen] = useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  //   setVoteResponse({ ...voteResponse, status: "", msg: "", error: "" });
  //   //setCaptcha("");
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  //get country by IP
  // const [country, setCountry] = useState("");
  // useEffect(() => {
  //   var ipAPI = "https://ip.nf/me.json";
  //   fetch(ipAPI, { method: "get" })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setCountry({ ...country, data: data.ip.country });
  //     });
  // }, []);

  // const form = useRef(null);

  // //validates fields
  // async function handleChange({ target }) {
  //   await form.current.validateFields(target);
  // }

  // async function handleSubmit(e) {
  //   e.preventDefault();

  //   // Validates the non-dirty fields and returns Promise<Field[]>
  //   await form.current.validateForm();

  //   if (form.current.isValid()) {
  //     var VOTE_API = `${global.API.rootPath}${global.config.endpoints.eventeer_vote}?access_key=${global.API.voteAccessKey}`;

  //     var voteHeaders = new Headers();
  //     voteHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  //     var bodyEncoded = new URLSearchParams();

  //     //POST DATA
  //     bodyEncoded.append("collection_id", collection_id);
  //     bodyEncoded.append(
  //       "event_id",
  //       event_data && event_data.app_id ? event_data.app_id : ""
  //     );
  //     bodyEncoded.append(
  //       "location",
  //       country && country.data ? country.data : "N/A"
  //     );
  //     bodyEncoded.append("email", e.target.elements.email.value);
  //     bodyEncoded.append("first_name", e.target.elements.firstName.value);
  //     bodyEncoded.append("last_name", e.target.elements.lastName.value);

  //     var requestOptions = {
  //       method: "POST",
  //       headers: voteHeaders,
  //       body: bodyEncoded,
  //       redirect: "follow",
  //     };
  //     fetch(VOTE_API, requestOptions)
  //       .then((response) => response.text())
  //       .then((result) => {
  //         const data = JSON.parse(result);
  //         setVoteResponse({
  //           ...voteResponse,
  //           status: data.status,
  //           msg: data.msg,
  //         });
  //         setVoteResponse({
  //           ...voteResponse,
  //           status: data.status,
  //           msg: data.msg,
  //         });
  //       })
  //       .catch((error) => {
  //         setVoteResponse({ ...voteResponse, status: false, error: true });
  //         console.log("error", error);
  //       });
  //   }
  //   // else if(!captcha) {
  //   //     setVoteResponse({ ...voteResponse, status: false, msg: "Captcha Failed" });
  //   // }
  //   else {
  //     console.log("The form is invalid");
  //   }
  // }

  return (
    <Box className={classes.nomineeCard}>
      <Box className={classes.nomineeSlide}>
        {/* {event_data.app_id && collection_id &&
                    event_data.app_id === "9704" && collection_id === "69" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9110" && collection_id === "72" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9692" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "8692" && collection_id === "76" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "8261" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10426"? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9559" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "8700" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9861" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10766" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10068" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10741" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9143" && collection_id === "91" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "7791" && collection_id === "92" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "7791" && collection_id === "107" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10745" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10306" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "5289" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "8900" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "8135" && collection_id === "109" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9212" && collection_id === "111" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10230" && collection_id === "112" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "7927" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "11587" && collection_id === "114" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9230" && collection_id === "81" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9230" && collection_id === "115" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "9559" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    event_data.app_id === "10601" && collection_id === "87" ? <img className={classes.Winner} src={Winner} alt="Eventeer Winner" /> :
                    ""
                } */}
        {event_data.images ? (
          <Slider {...settings} className="nomineeSlider">
            {event_data.images.map((gallary) => (
              <Box className={classes.nomineeSlide}>
                <img src={gallary.image} alt="nominee" />
              </Box>
            ))}
          </Slider>
        ) : event_data.cover_image ? (
          <Box className={classes.nomineeSlide}>
            <LazyLoad height={240}>
              <img src={event_data.cover_image} alt={event_data.app_title} />
            </LazyLoad>
          </Box>
        ) : (
          <Box className={classes.nomineeSlide}>
            <LazyLoad height={240}>
              <img src={Placeholder} alt={event_data.app_title} />
            </LazyLoad>
          </Box>
        )}
      </Box>
      <Box className={classes.nomineeCardText}>
        <Typography variant="h5" component="h2">
          {event_data.app_title}
        </Typography>
        <Typography variant="body1" className={classes.nomineeCardDesc}>
          {event_data.about_us.replace(
            /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&rsquo;|&amp;|&gt;/g,
            " "
          ).replace("Nestl&eacute; and L'Or&eacute;al", "Nestlé and L'Oréal")}
        </Typography>
        {event_data.app_id && event_data.app_id !== "13481" &&
          <Box className={classes.NomineeHigh}>
            <span>Event URL:</span>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              //href={"//" + event_data.application.url.replace(".eventeer", "")}
              href={event_data.app_id && event_data.app_id === "17124" ? "https://www.njpn.org/njpnac25/" : "//" + event_data.application.url.replace(".eventeer", "")}
            >
              {event_data.app_id && event_data.app_id === "17124" ? "https://www.njpn.org/njpnac25/" : event_data.application.url.replace(".eventeer", "")}{" "}
              <OpenInNewIcon />
            </a>
          </Box>
        }
        <Box className={classes.NomineeHigh} style={{gap: 34}}>
          <span>Host by:</span>
          <p>{event_data.organiser}</p>
        </Box>
        {/* <Box className={classes.nomineeCardBtns}>
          <Button variant="contained" color="primary" disabled>
            Voting Closed
          </Button> */}
          {/* <Link
            variant="outlined"
            color="primary"
            className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
            title="read more"
            to={
              "/eventeer-awards-2024/" +
              collection_id +
              "/nominee/" +
              event_data.app_id
            }
          >
            read more
          </Link> */}
        {/* </Box> */}
        {/* <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          className={classes.modalVoteStyle}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box className={classes.voteModal}>
              <IconButton
                aria-label="delete"
                className={classes.voteModalClose}
                onClick={handleClose}
                size="small"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              {voteResponse &&
              voteResponse.status &&
              voteResponse.msg ===
                "Please check your email for verification link!" ? (
                <Box className={classes.voteHeader}>
                  <img
                    src={thanks}
                    className={classes.voteThanks}
                    alt="Thank you for vote"
                  />
                  <Box className={classes.voteBox}>
                    <Typography variant="h2">
                      Please check your inbox.
                    </Typography>
                    <Typography variant="body1">
                      We sent a confirmation link via email. Please click it to
                      confirm your vote. You may need to check your spam box.
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.voteHeader}>
                  <img src={voteAuth} className={classes.voteAuth} alt="Vote" />
                  <Box className={classes.voteBox}>
                    <Typography variant="h2">Submit your vote</Typography>
                    <Typography variant="body1">
                      Please verify your information to finalize your vote.
                    </Typography>
                    <FormWithConstraints
                      ref={form}
                      onSubmit={handleSubmit}
                      noValidate
                      autoComplete="off"
                      className={classes.voteForm}
                    >
                      {voteResponse &&
                        !voteResponse.status &&
                        voteResponse.msg && (
                          <span class="error">{voteResponse.msg}</span>
                        )}
                      <input
                        type="text"
                        required
                        minLength={3}
                        name="firstName"
                        className={classes.voteInput}
                        placeholder="First Name*"
                        onChange={handleChange}
                      />
                      <FieldFeedbacks for="firstName">
                        <FieldFeedback when={(value) => value.length === 0}>
                          Please complete this required field.
                        </FieldFeedback>
                      </FieldFeedbacks>
                      <input
                        type="text"
                        required
                        minLength={3}
                        name="lastName"
                        className={classes.voteInput}
                        placeholder="Last Name*"
                        onChange={handleChange}
                      />
                      <FieldFeedbacks for="lastName">
                        <FieldFeedback when={(value) => value.length === 0}>
                          Please complete this required field.
                        </FieldFeedback>
                      </FieldFeedbacks>
                      <input
                        name="email"
                        required
                        minLength={3}
                        id="email"
                        className={classes.voteInput}
                        placeholder="Email Address*"
                        onChange={handleChange}
                      />
                      <FieldFeedbacks for="email">
                        <FieldFeedback when={(value) => value.length === 0}>
                          Please complete this required field.
                        </FieldFeedback>
                        <FieldFeedback when={(value) => !/\S+@\S+/.test(value)}>
                          Invalid email address.
                        </FieldFeedback>
                      </FieldFeedbacks>
                      <Reaptcha
                        sitekey="6LfY6pwiAAAAAEond0lK4rBcUH2dxIdfvU86ONMM"
                        onVerify={verifyCaptcha}
                        className={classes.voteCaptcha}
                      />
                      {voteResponse &&
                        voteResponse.msg === "Captcha Failed" && (
                          <span class="error">Prove you're not a Robot</span>
                        )}
                      <Button variant="contained" color="primary" type="submit">
                        Vote
                      </Button>
                    </FormWithConstraints>
                  </Box>
                </Box>
              )}
            </Box>
          </Fade>
        </Modal> */}
      </Box>
    </Box>
  );
};

export default NomineeCard;
