import { Box, Container, Typography } from '@material-ui/core';
import React from 'react'
import { winnerBannerStyles } from '../../utils/hooks/useApplyStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import winnerLogo from '../../assets/images/winner-logo.svg'
//import bCircle from '../../assets/images/circle-arrow-bottom.svg'

const BannerWinnerPage = () => {
    const styles = winnerBannerStyles();
    const classes = styles();
    return (
        <Box className={classes.banner2024}>
            <Container maxWidth="lg">
                <Box className={classes.bannerContent}>
                    <img src={winnerLogo} alt="winner logo" />
                    <Typography variant="h1">Honoring Creativity, Innovation & Success in Events</Typography>
                    <Typography variant="p" component="p" style={{color: '#fff', fontSize: 20}}>
                        Congratulations to all winners of the Eventeer Awards hosted by vFairs!
                    </Typography>
                    <a href='/eventeer-awards'>Eventeer Awards<ArrowForwardIcon /> </a>
                </Box>
            </Container>
        </Box>
    )
}

export default BannerWinnerPage