import React, { useRef, useState } from "react";
import BannerEventeerNomination2025 from "../../Banners/BannerEventeerNomination2025";
import DiscoverFooter from "../../Footer/Footer";
import NavigationBar from "../../Header/Navbar";
//import DiscoverCategoriesSlider from "./EventeerSection/DiscoverCategoriesSlider";
import {
  eventeerStyles,
  modalStyles,
} from "../../../utils/hooks/useApplyStyles";
import {
  Box,
  Typography,
  Button,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";
import DiscoverCategories from "./EventeerSection/DiscoverCategories";
//import Honor from "./EventeerSection/Honor";
//import NominationForm from './EventeerSection/NominationForm'
import QnA from "./EventeerSection/QnA";
import WinnerGet from "./EventeerSection/WinnerGet";
import SeePastWinners from "./SeePastWinners";
//import MoreEvents from './EventeerSection/MoreEvents'
import { useLocation } from "react-router-dom";
import likeThumbs from "../../../assets/images/likethumb.png";
import LazyLoad from "react-lazyload";

const EventeerAwardsNomination = () => {
  const search = useLocation().search;
  const getvoteQuery = new URLSearchParams(search).get("vote");

  const [open, setOpen] = useState(
    getvoteQuery && getvoteQuery === "confirmed" ? true : false
  );

  const submitVotesRef = useRef(null);
  const submitVotes = () => submitVotesRef.current.scrollIntoView(true);

  const handleClose = () => {
    setOpen(false);
    submitVotes();
  };
  const styles1 = eventeerStyles();
  const styles3 = modalStyles();
  const classes = { ...styles3(), ...styles1() };

  return (
    <div className="application">
      <NavigationBar submitVotes={submitVotes} />
      <BannerEventeerNomination2025 submitVotes={submitVotes} />
      {/* <Honor/> */}
      {/* <DiscoverCategoriesSlider /> */}
      {/* <DiscoverCategories submitVotesRef={submitVotesRef} /> */}
      {/* <NominationForm submitNominationRef={submitNominationRef}/> */}
      <WinnerGet />
      <SeePastWinners />
      {/* <MoreEvents /> */}
      <QnA />
      <DiscoverFooter />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={`${classes.modalBox} ${classes.likeModalBox}`}>
            <LazyLoad height={300}>
              <img src={likeThumbs} alt="likes" />
            </LazyLoad>
            <Box className={classes.voteContents}>
              <Typography variant="h2">
                Your vote has been confirmed!
              </Typography>
              <Typography variant="body1">
                Explore more categories and nominees to vote your favorite one.
              </Typography>
              <div className={classes.voteContentBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Explore More
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default EventeerAwardsNomination;
