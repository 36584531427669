import { Box, Typography, Grid } from '@material-ui/core';
import React from 'react'
import { awardWinnerCardStyles } from '../../utils/hooks/useApplyStyles';
//import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import winTagIcon from '../../assets/images/winnertag-icon.svg'
import LashCast from '../../assets/Eventeer2025/company-logos/LashCast.png'
import Lynx from '../../assets/Eventeer2025/company-logos/Lynx.png'
import Maryland from '../../assets/Eventeer2025/company-logos/Maryland.png'
import Placement from '../../assets/Eventeer2025/company-logos/Placement.png'
import SCA from '../../assets/Eventeer2025/company-logos/SCA.png'
import Abertay from '../../assets/Eventeer2025/company-logos/Abertay.png'
import Rothkoff from '../../assets/Eventeer2025/company-logos/Rothkoff.png'
import ISA from '../../assets/Eventeer2025/company-logos/ISA.png'
import BAB from '../../assets/Eventeer2025/company-logos/Bab.png'
import BLAXPO from '../../assets/Eventeer2025/company-logos/blaxpo.png'
import fbg from '../../assets/Eventeer2025/company-logos/fbg.png'
import HSS from '../../assets/Eventeer2025/company-logos/HSS.png'
import NCW from '../../assets/Eventeer2025/company-logos/NCW.png'
import ebay from '../../assets/Eventeer2025/company-logos/eBay.png'
import Olympics from '../../assets/Eventeer2025/company-logos/Olympics.png'
import SACNAS from '../../assets/Eventeer2025/company-logos/SACNAS.webp'
import Raven from '../../assets/Eventeer2025/company-logos/Ravens.png'
import Nestle from '../../assets/Eventeer2025/company-logos/Nestle.png'
import Association from '../../assets/Eventeer2025/company-logos/Association.png'
import Vista from '../../assets/Eventeer2025/company-logos/Vista.png'

const AwardWinnerCard = () => {
    const styles = awardWinnerCardStyles();
    const classes = styles();
    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/13991/content/images/1737817779blob1737817779" alt="LASHCON" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Conference</Typography>
                            <Typography variant="h2">LASHCON</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={LashCast} alt="LASHCON" />
                                </Box>
                                <Typography variant="body1">Lashconference</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9267/content/images/1738043603blob1738043603" alt="VFoodFair 2024" />
                            </Box>                            
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"> <img src={winTagIcon} alt="WinnerIn" />Best Trade Show</Typography>
                            <Typography variant="h2">VFoodFair 2024</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={Lynx} alt="VFoodFair 2024" />
                                </Box>
                                <Typography variant="body1">Lynx Expo</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/1385/content/images/1738000505blob1738000505" alt="UMGC Virtual InternPLUS Fair Sept 2024" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"> <img src={winTagIcon} alt="WinnerIn" />Best Hiring Fair</Typography>
                            <Typography variant="h2">UMGC Virtual InternPLUS Fair Sept 2024</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                    <Box className={classes.awWinIcon}>
                                        <img src={Maryland} alt="UMGC Virtual InternPLUS Fair Sept 2024" />
                                    </Box>
                                    <Typography variant="body1">University of Maryland Global Campus</Typography>
                                </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1738406073blob1738406073" alt="TPE Virtual Grad Program Fair" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"> <img src={winTagIcon} alt="WinnerIn" />Best Education Fair</Typography>
                            <Typography variant="h2">TPE Virtual Grad Program Fair</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={Placement} alt="TPE Virtual Grad Program Fair" />
                                </Box>
                                <Typography variant="body1">The Placement Exchange</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10438/content/images/17384077222024-sca-vitual-png1738407722.PNG" alt="Virtual Conservation Career Fair 2024" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Non-profit Event</Typography>
                            <Typography variant="h2">Virtual Conservation Career Fair 2024</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={SCA} alt="Virtual Conservation Career Fair 2024" />
                                </Box>
                                <Typography variant="body1">Student Conservation Association</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1737984218blob1737984218" alt="2024 Dundee, UK Cyber 9/12 Strategy Challenge" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"> <img src={winTagIcon} alt="WinnerIn" />Best University Event</Typography>
                            <Typography variant="h2">2024 Dundee, UK Cyber 9/12 Strategy Challenge</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={Abertay} alt="2024 Dundee, UK Cyber 9/12 Strategy Challenge" />
                                </Box>
                                <Typography variant="body1">Abertay University</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain} >
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew/4703/content/images/1738421678hybrid-elder-care-symposium-2024-nj-amp-pa-png1738421678.PNG" alt="Hybrid Elder Care Symposium 2024 NJ & PA" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Healthcare Event</Typography>
                            <Typography variant="h2">Hybrid Elder Care Symposium 2024 NJ & PA</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={Rothkoff} alt="Hybrid Elder Care Symposium 2024 NJ & PA" />
                                </Box>
                                <Typography variant="body1">Rothkoff Law Group</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/16962/content/images/1738341893blob1738341893" alt="ISA OT Cybersecurity Summit - London" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Networking Event</Typography>
                        <Typography variant="h2">ISA OT Cybersecurity Summit - London</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={ISA} alt="ISA OT Cybersecurity Summit - London" />
                            </Box>
                            <Typography variant="body1">International Society of Automation</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/10532/content/images/1737997845blob1737997845" alt="المعرض الافتراضي للتوظيف" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"> <img src={winTagIcon} alt="WinnerIn" />Best Exhibition Experience</Typography>
                        <Typography variant="h2">المعرض الافتراضي للتوظيف</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={BAB} alt="المعرض الافتراضي للتوظيف" />
                            </Box>
                            <Typography variant="body1">Bab Rizq Jameel</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src={BLAXPO} alt="BLAXPO '24" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Hybrid Event</Typography>
                        <Typography variant="h2">BLAXPO '24</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={fbg} alt="BLAXPO '24" />
                            </Box>
                            <Typography variant="body1">Four Brown Girls</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/11346/content/images/1738437105community-education-amp-outreach-at-hss-jpg1738437105.jpg" alt="Community Education & Outreach at HSS" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Out of the Box Event</Typography>
                        <Typography variant="h2">Community Education & Outreach at HSS</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={HSS} alt="Community Education & Outreach at HSS" />
                            </Box>
                            <Typography variant="body1">Hospital for Special Surgery</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1737970201blob1737970201" alt="ISA Automation Summit and Expo" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Mobile App Event</Typography>
                        <Typography variant="h2">ISA Automation Summit and Expo</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={ISA} alt="ISA Automation Summit and Expo" />
                            </Box>
                            <Typography variant="body1">International Society of Automation</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9076/content/images/1738438108ncwit-png1738438108.PNG" alt="The National Center for Women & Information Technology (NCWIT): 2024 Virtual Career Fair" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Tech Industry Event</Typography>
                        <Typography variant="h2">The National Center for Women & Information Technology (NCWIT): 2024 Virtual Career Fair</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={NCW} alt="The National Center for Women & Information Technology (NCWIT): 2024 Virtual Career Fair" />
                            </Box>
                            <Typography variant="body1">National Center for Women & Information Technology</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/15804/content/images/1738049537blob1738049537" alt="Ebay Holidazzle" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Internal Event</Typography>
                        <Typography variant="h2">Ebay Holidazzle</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={ebay} alt="Ebay Holidazzle" />
                            </Box>
                            <Typography variant="body1">eBay</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1738047214blob1738047214" alt="Global Virtual Youth and Educator Summit" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Virtual Event</Typography>
                        <Typography variant="h2">Global Virtual Youth and Educator Summit</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={Olympics} alt="Global Virtual Youth and Educator Summit" />
                            </Box>
                            <Typography variant="body1">Special Olympics International</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1737970201blob1737970201" alt="ISA Automation Summit and Expo" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best In-Person Event</Typography>
                        <Typography variant="h2">ISA Automation Summit and Expo</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={ISA} alt="ISA Automation Summit and Expo" />
                            </Box>
                            <Typography variant="body1">International Society of Automation</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8132/content/images/1738443749sacnas-careercon-2024-png1738443749.PNG" alt="2024 SACNAS CareerCon" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Immersive Event Design</Typography>
                        <Typography variant="h2">2024 SACNAS CareerCon</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={SACNAS} alt="2024 SACNAS CareerCon" />
                            </Box>
                            <Typography variant="body1">SACNAS</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/16975/content/images/1738405359blob1738405359" alt="Pharmacy Careers Summit 2024" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Summit</Typography>
                        <Typography variant="h2">Pharmacy Careers Summit 2024</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={Raven} alt="Pharmacy Careers Summit 2024" />
                            </Box>
                            <Typography variant="body1">Raven's Recruitment</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/7791/content/images/1737970201blob1737970201" alt="ISA Automation Summit and Expo" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Event - North America</Typography>
                        <Typography variant="h2">ISA Automation Summit and Expo</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={ISA} alt="ISA Automation Summit and Expo" />
                            </Box>
                            <Typography variant="body1">International Society of Automation</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/14950/content/images/1738445370nxl-season3-png1738445370.PNG" alt="Nxlseason3" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Event - EMEA</Typography>
                        <Typography variant="h2">Nxlseason3</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={Nestle} alt="Nxlseason3" />
                            </Box>
                            <Typography variant="body1">Nestlé and L'Oréal</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/16975/content/images/1738405359blob1738405359" alt="Pharmacy Careers Summit 2024" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Event - APAC</Typography>
                        <Typography variant="h2">Pharmacy Careers Summit 2024</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={Raven} alt="Pharmacy Careers Summit 2024" />
                            </Box>
                            <Typography variant="body1">Raven's Recruitment</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/11610/content/images/1738444976acet-png1738444976.PNG" alt="ACET: Celebrating our Legacy and Embracing our Future" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Association Event</Typography>
                        <Typography variant="h2">ACET: Celebrating our Legacy and Embracing our Future</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={Association} alt="ACET: Celebrating our Legacy and Embracing our Future" />
                            </Box>
                            <Typography variant="body1">Association for Compensatory Educators of Texas</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew/9093/content/images/1685724468brain-injury-awareness-day-png1685724468.PNG" alt="Brain Injury Awareness Day" />
                        </Box>
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Registration Experience</Typography>
                        <Typography variant="h2">Brain Injury Awareness Day</Typography>
                        <Box className={classes.awWinOrgTitle}>
                            <Box className={classes.awWinIcon}>
                                <img src={Vista} alt="Brain Injury Awareness Day" />
                            </Box>
                            <Typography variant="body1">Vista Center Brain Injury Services</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                    <Box className={classes.awWin}>
                        <Box className={classes.awWinT}>
                            <Box className={classes.awWinImg}>
                                <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1737984218blob1737984218" alt="2024 Dundee, UK Cyber 9/12 Strategy Challenge" />
                            </Box>
                        </Box>
                        <Box className={classes.ChipawWinText}>
                            <Typography variant="body1"> <img src={winTagIcon} alt="WinnerIn" />Best Event Professional</Typography>
                            <Typography variant="h2">2024 Dundee, UK Cyber 9/12 Strategy Challenge</Typography>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src={Abertay} alt="2024 Dundee, UK Cyber 9/12 Strategy Challenge" />
                                </Box>
                                <Typography variant="body1">Abertay University</Typography>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
        </Grid>
    )
}

export default AwardWinnerCard