import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { useState } from "react";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import AddIcon from "@material-ui/icons/Add";

const QnA = () => {
  const style = eventeerStyles();
  const classes = style();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box className={classes.qna}>
      <Container maxLength="lg">
        <Box className={classes.EventeerWrap}>
          <Typography
            variant="h2"
            align="center"
            className={classes.eventeerTitle}
          >
            Frequently Asked Questions
          </Typography>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
              When will be next Eventeer Awards?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                Eventeer Awards are an annual event that honor best customer events during the previous year. You can ask your project manager or CSM for an update. Our team also promotes the awards via email and social media.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
              How are winners selected?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                Winners are announced based on voting. The customers are welcome to nominate their events. Once the nomination phase ends, they are encouraged to collect votes.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
              Is it free of cost?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                Yes, absolutely. The awards are our way of appreciating all the wonderful events our customers host.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
};

export default QnA;
