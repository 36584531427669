import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import { oldWinnerStyles } from '../../../utils/hooks/useApplyStyles';
import LashCast from '../../../assets/Eventeer2025/company-logos/LashCast.png'
import Lynx from '../../../assets/Eventeer2025/company-logos/Lynx.png'
import Maryland from '../../../assets/Eventeer2025/company-logos/Maryland.png'
import Placement from '../../../assets/Eventeer2025/company-logos/Placement.png'
import SCA from '../../../assets/Eventeer2025/company-logos/SCA.png'
import Abertay from '../../../assets/Eventeer2025/company-logos/Abertay.png'
import Rothkoff from '../../../assets/Eventeer2025/company-logos/Rothkoff.png'
import ISA from '../../../assets/Eventeer2025/company-logos/ISA.png'
import BAB from '../../../assets/Eventeer2025/company-logos/Bab.png'
import fbg from '../../../assets/Eventeer2025/company-logos/fbg.png'
import HSS from '../../../assets/Eventeer2025/company-logos/HSS.png'
import NCW from '../../../assets/Eventeer2025/company-logos/NCW.png'
import ebay from '../../../assets/Eventeer2025/company-logos/eBay.png'
import Olympics from '../../../assets/Eventeer2025/company-logos/Olympics.png'
import SACNAS from '../../../assets/Eventeer2025/company-logos/SACNAS.webp'
import Raven from '../../../assets/Eventeer2025/company-logos/Ravens.png'
import Nestle from '../../../assets/Eventeer2025/company-logos/Nestle.png'
import Association from '../../../assets/Eventeer2025/company-logos/Association.png'
import Vista from '../../../assets/Eventeer2025/company-logos/Vista.png'



const OldWinners = () => {
    const styles = oldWinnerStyles();
    const classes = styles();
    return (
        <Container maxWidth="lg">
            <Box className={classes.lWinner}>
                <Box className={classes.lWinnerText}>
                    <Typography variant="h2">
                    Meet the Winners of Eventeer Awards 2025
                    </Typography>
                    <Typography variant="body1">
                    2024 was an amazing year for the event organizers as they thrived with new event trends and futuristic ideas. Meet the winners of Eventeer Awards 2025 who aced a tough competition with hundreds of events among 25 different categories.
                    </Typography>
                </Box>
                <Box className={classes.awLogoRow}>
                    <Box className={classes.awlogobox}>
                        <img src={LashCast} alt="LashCast" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Lynx} alt="Lynx Exhibitions" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Maryland} alt="Maryland" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Placement} alt="Placement" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={SCA} alt="SCA" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Abertay} alt="Abertay" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Rothkoff} alt="Rothkoff" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={ISA} alt="ISA" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={BAB} alt="BAB" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={HSS} alt="BLAXPO" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={fbg} alt="fbg" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={NCW}alt="NCW" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={ebay} alt="eBay" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Olympics} alt="Olympics" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={SACNAS} alt="SACNAS" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Raven} alt="Raven" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Nestle} alt="Nestle" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Association} alt="Association" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src={Vista} alt="Vista" />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default OldWinners